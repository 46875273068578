<template>
  <CmsLayout class="vod-main-page">
    <main class="container-1440">
      <PageTitle :crumbs="crumbs" :text="getTranslation('vod')" />

      <div v-if="isLoaded && !listOfSourceIds.length" class="error-block with-side-padding">
        <h4 v-html="getTranslation('vod_unavailable_error_profile')" />
        <h4 v-html="getTranslation('vod_unavailable_error_default')" />
      </div>

      <template v-else>
        <TilesVodNavigationGroup
          class="pb-48 pb-tablet-32 pb-mobile-16 with-side-padding"
          data-cy="vod-main-page"
          :show-header="false"
          @gaTileClick="gaTileClick"
        />

        <h2
          v-if="$store.vod.states.recommendationsLoaded"
          class="with-side-padding mb-48 mb-tablet-32 mb-mobile-32"
          v-html="getTranslation('new_in_catalogs')"
        />

        <VodRecommendations gaEventCategory="vod" />
      </template>
    </main>
  </CmsLayout>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import Vod from 'src/mixins/Vod';
import Global from 'src/mixins/Global';
import CmsLayout from 'src/layouts/CmsLayout.vue';
import VodRecommendations from 'src/components/vod/VodRecommendations.vue';
import TilesVodNavigationGroup from 'src/components/ui/tiles/TilesVodNavigationGroup.vue';
import Seo from 'src/mixins/Seo';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import { Watch } from 'vue-property-decorator';
import PageTitle from 'src/components/ui/PageTitle.vue';

@Component({
  components: { CmsLayout, TilesVodNavigationGroup, VodRecommendations, PageTitle },
})
export default class VodMainPage extends mixins(Global, Vod, Seo) {
  crumbs = [{ name: this.getTranslation('menu_homescreen'), link: '/' }];

  @Watch('$store.vod.states.recommendationsLoaded')
  onVodSourcesLoadedChange(val: boolean) {
    if (val) {
      actions.common.showFooter(this.$store);
    }
  }

  get listOfSourceIds() {
    return selectors.vod.listOfSourceIdsWithoutArchiveSelector(this.$store);
  }

  get listOfSourceIdsWithoutArchive() {
    return selectors.vod.listOfSourceIdsWithoutArchiveSelector(this.$store);
  }

  get isLoaded() {
    return this.vodSourcesLoaded && !this.vodSourcesLoading;
  }

  mounted() {
    if (
      this.$store.vod.states.recommendationsLoaded ||
      this.listOfSourceIdsWithoutArchive.includes(this.sourceId)
    ) {
      actions.common.showFooter(this.$store);
    }
  }

  destroyed() {
    actions.common.hideFooter(this.$store);
  }

  getMetaInfo() {
    const meta = [
      {
        name: 'description',
        content: this.getTranslation('vod_main_page_meta_description'),
      },
      {
        name: 'keywords',
        content: this.getTranslation('vod_main_page_meta_keywords'),
      },
      {
        name: 'og:title',
        content: this.getTranslation('vod_main_page_meta_title'),
      },
      {
        name: 'og:description',
        content: this.getTranslation('vod_main_page_meta_keywords'),
      },
    ];

    const metaImg = this.getDefaultMetaOgImage();

    if (metaImg) {
      meta.push({
        name: 'og:image',
        content: metaImg,
      });
    }

    return {
      title: this.getTranslation('vod_main_page_meta_title'),
      meta,
    };
  }

  get seoBreadcrumbs() {
    return [
      { name: this.getTranslation('breadcrumb_smotreshka'), link: '/' },
      { name: this.getTranslation('breadcrumb_cinemas'), link: '/vod' },
    ];
  }

  gaTileClick(vod: string | undefined) {
    this.gaEvent({
      category: 'vod',
      action: 'Клик по тайтлу в стрипе',
      strip_name: 'Кинотеатр',
      vod_name: vod,
    });
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.container-1440 {
  margin-top: 64px;

  @include tablet {
    margin-top: 48px;
  }

  @include mobile {
    margin-top: 32px;
  }
}
</style>
