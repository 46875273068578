<template>
  <CollectionLayout
    type="vod"
    :is-loaded="isLoaded"
    :is-collection-loaded="isCollectionLoaded"
    :show-error="showError || !isSourceAvailable"
  >
    <VodCollectionDetails
      v-if="isLoaded && !showError && collection && isCollectionAvailable"
      class="container-1440"
      :collection="collection"
    />
  </CollectionLayout>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import VodCollectionDetails from 'src/components/vod/VodCollectionDetails.vue';
import * as api from 'src/api';
import { Watch } from 'vue-property-decorator';
import { VOD_TITLES_DEFAULT_LIMIT } from 'src/constants';
import CollectionLayout from 'src/layouts/CollectionLayout.vue';
import logger from 'src/utils/logger';

const log = logger('vod-collection-page');

@Component({
  components: {
    CollectionLayout,
    VodCollectionDetails,
  },
})
export default class VodCollectionPage extends SequoiaComponent {
  isCollectionLoaded = false;
  showError = false;
  loadMore = false;
  isMounted = false;

  @Watch('$store.vod.states.sourcesLoaded')
  async onVodSourcesLoadedChange(val: boolean) {
    if (val) {
      this.showError = false;
      actions.vod.resetCollections(this.$store);
      await actions.common.removeAssetTokens(this.$store);
      await this.loadCollection();
    }
  }

  @Watch('isCollectionAvailable')
  onIsCollectionAvailable(val: boolean) {
    if (!val && process.env.VUE_ENV === 'server') {
      this.$ssrContext.httpStatus = 404;
    }
  }

  get isLoaded() {
    return (
      this.isMounted && this.isCollectionLoaded && this.vodSourcesLoaded && !this.vodSourcesLoading
    );
  }

  get vodSourcesLoaded() {
    return selectors.vod.sourcesLoadedSelector(this.$store);
  }

  get vodSourcesLoading() {
    return selectors.vod.sourcesLoadingSelector(this.$store);
  }

  get listOfAllSourceIds() {
    return selectors.vod.listOfAllSourceIdsSelector(this.$store);
  }

  get isCollectionAvailable() {
    return this.listOfAllSourceIds.includes(this.collection?.vodSource || '');
  }

  get collections() {
    return selectors.vod.collectionsSelector(this.$store);
  }

  get collection() {
    return selectors.vod.currentCollectionSelector(this.$store);
  }

  get collectionId() {
    return this.$route.params.id;
  }

  get isSourceAvailable() {
    return !!this.$store.vod.sources[this.collection?.vodSource || this.sourceId];
  }

  get sourceId() {
    return this.$route.params.sourceId;
  }

  async mounted() {
    await actions.tvChannels.loadChannelsAndGenres(this.$store);

    await this.loadCollection();
    this.isMounted = true;

    // for navigating via history Back <- button
    window.onpopstate = () => {
      actions.vod.setCurrentCollectionId(this.$store, this.$route.params.id);
    };
  }

  beforeDestroy() {
    actions.vod.setCurrentCollectionId(this.$store, '');
  }

  async loadCollection() {
    actions.vod.setCurrentCollectionId(this.$store, this.collectionId);

    if (this.sourceId) {
      // for collections by source
      const titles = (
        await api.vod
          .getCollectionTitles(this.sourceId, this.collectionId, true)
          .catch((err) => {
            this.showError = true;
            log.error('getCollectionTitles:', err);
          })
          .finally(() => {
            this.isCollectionLoaded = true;
          })
      )?.titles;

      this.$store.vod.states.shouldLoadMoreTitlesInCollection = !!(
        titles?.length && titles?.length === VOD_TITLES_DEFAULT_LIMIT
      );

      if (titles) {
        actions.vod.addTitlesToCollectionFromSource(
          this.$store,
          this.collectionId,
          this.sourceId,
          titles
        );

        this.loadMore = false;
      }
    } else if (this.collections?.length) {
      this.isCollectionLoaded = true;
    } else if (!this.collections?.length) {
      // for collections on home screen
      await actions.vod
        .loadCollections(this.$store)
        .catch((err) => {
          this.showError = true;
          log.error('loadCollections:', err);
        })
        .finally(async () => {
          this.isCollectionLoaded = true;
          if (!this.collection) {
            this.showError = true;
          }
        });

      this.loadMore = true;
    }

    await actions.vod.showCollection(
      this.$store,
      this.collectionId,
      !!this.sourceId,
      this.loadMore,
      false
    );
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/side-and-bottom-padding';

.collection-details {
  &::v-deep {
    .with-side-and-bottom-padding {
      @include side-and-bottom-padding;

      &.top {
        margin-top: 0;
      }
    }

    .bottom.row {
      margin: 0;
    }
  }
}
</style>
