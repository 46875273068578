<template>
  <VodDetails v-if="!isError404" type="vod" />

  <Error404Page v-else />
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import VodDetails from 'src/components/vod/VodDetails.vue';
import Global from 'src/mixins/Global';
import Vod from 'src/mixins/Vod';
import { actions } from 'src/store/actions';
import Seo from 'src/mixins/Seo';
import { transliterate } from 'src/utils/language';
import { selectors } from 'src/store/selectors';
import { HTTP_CODES } from 'src/constants';
import random from 'lodash/random';
import Error404Page from 'src/pages/error/Error404Page.vue';

@Component({
  components: {
    Error404Page,
    VodDetails,
  },
})
export default class VodTitlePage extends mixins(Global, Vod, Seo) {
  isError404 = false;

  async serverPrefetch() {
    const { sourceId, titleId } = this.$route.params;
    if (!selectors.vod.listOfSourceIdsWithoutArchiveSelector(this.$store).includes(sourceId)) {
      if (typeof selectors.account.isAnonymousSelector(this.$store) !== 'undefined') {
        this.isError404 = true;
      } else {
        actions.player.setError(this.$store, HTTP_CODES.FORBIDDEN);
      }
    } else if (this.$store.vod.sources[sourceId] && sourceId && titleId) {
      await actions.vod.loadTitleVod(this.$store, sourceId, titleId);
      if (this.title) {
        this.initMeta();
        actions.seo.addBreadcrumbProduct(this.$store, this.breadcrumbProduct);
        actions.seo.addBreadcrumbs(this.$store, this.seoBreadcrumbs);
      } else {
        this.isError404 = true;
      }
    }

    actions.common.showFooter(this.$store);
  }

  get seoBreadcrumbs() {
    return [
      { name: this.getTranslation('breadcrumb_smotreshka'), link: '/' },
      { name: this.getTranslation('breadcrumb_cinemas'), link: '/vod' },
      { name: this.getTranslation(this.sourceId), link: `/vod/${this.sourceId}` },
      {
        name: this.title?.preview?.title || '',
        link: `/vod/${this.sourceId}/${this.titleIdFromParams}`,
      },
    ];
  }

  get breadcrumbProduct() {
    return {
      name: this.title?.preview?.title || '',
      image:
        this.title?.preview?.additionalImages?.find(
          (img: { originalType: string }) => img.originalType === 'fullscreen'
        )?.path || this.title?.preview?.posters?.[0]?.path,
      description: this.title?.details?.description,
      brand: this.getTranslation('breadcrumb_smotreshka'),
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue:
          this.title?.preview?.ratingKp?.toFixed(1) ||
          this.title?.preview?.ratingImdb?.toFixed(1) ||
          '',
        reviewCount: random(10, 1000).toString(),
        bestRating: '10',
      },
    };
  }

  getMetaInfo() {
    if (!this.title) {
      return {
        title: '',
        meta: [],
      };
    }

    const title = this.title?.preview?.title || '';
    const type = this.title?.preview?.categories?.[0]
      ? `${this.getTranslation(
          `category_${transliterate(this.title?.preview?.categories?.[0]?.title || '')}_singular`
        )}`
      : '';

    const year = this.title?.preview?.years?.[0];
    const titleDescriptionFirstSentence = this.title?.details?.description.split('. ')[0];

    const metaTitle = this.getTranslation('vod_meta_title')
      .replace(/%titleName%/g, title)
      .replace(/%titleType%/g, type)
      .replace(/%year%/g, year ? `(${year})` : '')
      .replace(/%vodName%/g, this.source?.customTitle[this.languageCode]);

    const metaDescription = this.getTranslation('vod_meta_description')
      .replace(/%titleType%/g, type)
      .replace(/%titleName%/g, title)
      .replace(/%year%/g, year ? `(${year})` : '')
      .replace(/%first_sentence%/g, titleDescriptionFirstSentence || '')
      .replace(/%vodName%/g, this.source?.customTitle[this.languageCode]);

    const image =
      this.title?.preview?.additionalImages?.find(
        (img: { originalType: string }) => img.originalType === 'fullscreen'
      )?.path || this.title?.preview?.posters?.[0]?.path;

    return {
      title: metaTitle,
      meta: [
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: this.getTranslation('vod_meta_keywords')
            .replace(/%titleName%/g, title)
            .replace(/%titleType%/g, type),
        },
        {
          name: 'og:title',
          content: metaTitle,
        },
        {
          name: 'og:description',
          content: metaDescription,
        },
        {
          name: 'og:image',
          content: image,
        },
      ],
    };
  }
}
</script>
